/* Normal */
@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
}

/* Italic */
@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto-Italic.ttf') format('truetype');
  font-style: italic;
  font-weight: 400;
}

/* Medium */
@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto-Medium.ttf') format('truetype');
  font-style: normal;
  font-weight: 500;
}

/* Medium Italic */
@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto-MediumItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 500;
}

/* Bold */
@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
}

/* Bold Italic */
@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto-BoldItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 700;
}

/* Mono */
@font-face {
  font-family: 'Roboto Mono';
  src: url('/fonts/RobotoMono-VariableFont_wght.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
}

/* Slab */
@font-face {
  font-family: 'Roboto Slab';
  src: url('/fonts/RobotoSlab-VariableFont_wght.ttf') format('truetype');
  font-style: normal;
  font-weight: 400 500;
}

/* Jakarta */
@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('/fonts/PlusJakartaSans-VariableFont_wght.ttf') format('truetype');
  font-style: normal;
  font-weight: 400 500;
}

/* Jakarta Bold */
/* @font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('/fonts/PlusJakartaSans-VariableFont_wght.ttf') format('truetype');
  font-style: bold;
  font-weight: 700;
} */

/* Jakarta italic */
/* @font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('/fonts/PlusJakartaSans-Italic-VariableFont_wght.ttf') format('truetype');
  font-style: italic;
  font-weight: 400 700;
} */

:root {
  --color-primary-darker: #003a47;
  --color-accent-dark: #a03123;
  --color-on-dark: white;
  --color-on-light: #242424;
  --color-warning: #ffb71b;
  --color-warning-dark: #755000;
  --color-skylight: #b2e3ee;
  --color-green: #00856a;
  --color-neutral-xx-light: white;
  --color-neutral-x-light: #f4f4f4;
  --color-neutral-light: #d8d8d8;
  --color-neutral-x-dark: #262626;
  --color-neutral-xx-dark: black;
  --color-outline-medium: #949494;
  --color-pdf-control: #323639;

  --color-primary-x-light: #e3f5f0;
  --color-primary-light: #aae2d0;
  --color-primary: #0d8484;
  --color-primary-dark: #006272;
  --color-accent: #dd372f;
  --color-accent-light: #e97872;
  --color-neutral-minus: #727272;
  --color-neutral: #676767;
  --color-neutral-dark: #424242;
  --color-neutral-tr70: #676767b3;

  --color-background: white;
  --color-background-tr00: #ffffff00;
  --color-foreground: #424242;
  --color-foreground-tr10: #4242421a;

  --link-btn-base-color: var(--color-primary-dark);
  --link-btn-base-color-tr05: #0d84840d;
  --link-btn-base-color-tr10: #0d84841a;
  --link-btn-base-color-tr20: #0d848433;
  --link-btn-base-color-tr50: #0d848480;
  --link-btn-base-color-tr80: #0d8484cd;
  --link-btn-fill-color: var(--color-background);

  --color-important: #a03123;

  --card-base-color: white;
  --card-fill-color: var(--color-neutral-dark);

  --header-base-color: var(--color-primary);
  --header-fill-color: white;

  --sub-header-base-color: var(--color-primary-x-light);
  --sub-header-fill-color: var(--color-neutral-x-dark);

  --footer-base-color: var(--color-neutral-dark);
  --footer-fill-color: white;

  --search-border-color: #d8d8d8;
  --search-bg-color: white;
  --search-fill-color: #424242;
  --search-heading-color: #676767;
  --search-focus-bg-color: #d8d8d8;
  --search-focus-border-color: #424242;
  --search-outline-color: #424242;
  --search-entry-hover-color: #f4f4f4;

  --success-base-color: #cce7e1;
  --warning-base-color: #fff1d1;
  --info-base-color: #d8f1f6;
  --error-base-color: #f8d7d5;
  --messages-fill-color: #424242;

  --base-font-size: 16px;
  --font-family-primary: 'Roboto', Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    -apple-system, BlinkMacSystemFont, Segoe UI, sans-serif;
  --font-family-serif: 'Roboto Slab', Palatino, Palatino Linotype, Palatino LT STD, Book Antiqua,
    Georgia, serif;
  --font-family-mono: 'Roboto Mono', courier, monospace;

  --max-client-logo-width: 160px;
  --max-client-logo-height: 80px;

  /*
  Transparency Chart
  %    Dec  Hex   Suffix
  0.0    0   00   -tr00
  0.05  13   0d   -tr05
  0.1   26   1a   -tr10
  0.2   51   33   -tr20
  0.25  64   40   -tr25
  0.3   77   4d   -tr30
  0.4  102   66   -tr40
  0.5  128   80   -tr50
  0.6  154   9a   -tr60
  0.7  179   b3   -tr70
  0.75 192   c0   -tr75
  0.8  205   cd   -tr80
  0.9  230   e6   -tr90
  1.0  255   ff   -tr100
*/

  --color-primary-tr05: #0d84840d;
  --color-primary-tr10: #0d84841a;
  --color-primary-tr20: #0d848433;
  --color-primary-tr30: #0d84844d;
  --color-accent-tr10: #dd372f1a;
  --color-accent-tr40: #dd372f66;
  --color-neutral-xx-light-tr00: #ffffff00;
  --color-neutral-xx-light-tr60: #ffffff9a;
  --color-neutral-light-tr70: #d8d8d8b3;
  --color-neutral-xx-dark-tr10: #0000001a;
  --color-neutral-xx-dark-tr20: #00000033;
  --color-neutral-xx-dark-tr25: #00000040;
}

html,
body {
  direction: ltr;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: var(--font-family-primary);
  color: white;
  font-size: var(--base-font-size);
  line-height: 1.8;
  background-color: var(--color-background, white);
  min-height: 100vh;
  width: 100%;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html[lang='ar'] body,
html[lang='dv'] body,
html[lang='fa'] body,
html[lang='ha'] body,
html[lang='he'] body,
html[lang='kr'] body,
html[lang='ku'] body,
html[lang='ps'] body,
html[lang='ur'] body,
html[lang='yi'] body {
  direction: rtl;
}

#__next {
  min-height: 100vh;
}

main,
div[role='main'] {
  width: 100%;
  color: var(--color-foreground, #424242);
  padding: 1rem 1rem 2rem;
  background: var(--color-background, white);
  min-height: 100vh;
}

h1 {
  margin: 0 0 2rem 0;
  font-size: 2em;
  letter-spacing: 1.3px;
  line-height: 1.3;
}

h2 {
  font-size: 1.5em;
  margin: 0 0 1.5rem 0;
  letter-spacing: 1.1px;
  line-height: 1.3;
}

h3 {
  font-size: 1.3em;
  margin: 0 0 0.3rem 0;
  letter-spacing: 1.1px;
  line-height: 1.3;
}

ol li,
ul li {
  font-size: 1em;
  line-height: 1.4em;
  margin-bottom: 8px;
  margin-left: 0;
}

img {
  max-width: 100%;
}

.main-grad {
  background-color: var(--color-primary);
  background:
    radial-gradient(
        circle at bottom left,
        var(--color-primary-tr20) 0%,
        var(--color-neutral-xx-light-tr00) 50%,
        var(--color-neutral-xx-light-tr00) 100%
      )
      bottom left,
    radial-gradient(
        circle at center right,
        var(--color-primary-tr30, #0d84844d) 0%,
        var(--color-neutral-xx-light-tr00) 70%,
        var(--color-neutral-xx-light-tr00) 100%
      )
      center right;
  background-size: 50% 100%;
  background-repeat: no-repeat;
}

a {
  min-height: 24px;
  text-decoration: underline;
  color: var(--link-btn-base-color);
  transition:
    color 0.2s ease-out,
    background-color 0.2s ease-out;
}

a:hover,
a:focus {
  color: var(--link-btn-base-color);
}

a:focus {
  outline: 2px dotted var(--link-btn-base-color);
  outline-offset: 2px;
}

button:focus,
input:focus,
select:focus,
.focus-outline:focus {
  outline: 2px dotted var(--color-foreground);
  outline-offset: 2px;
}

.offscreen-text {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  background-color: var(--color-background-tr00, white);
  color: black;
}

.print-only {
  display: none;
}

@media screen and (max-width: 675px) {
  .main-grad {
    background:
      radial-gradient(
          circle at bottom left,
          var(--color-primary-tr20, #0d848433) 0%,
          var(--color-background-tr00, #ffffff00) 30%,
          var(--color-background-tr00, #ffffff00) 100%
        )
        bottom left,
      radial-gradient(
          circle at center right,
          var(--color-primary-tr30, #0d84844d) 0%,
          var(--color-background-tr00, #ffffff00) 50%,
          var(--color-background-tr00, #ffffff00) 100%
        )
        center right;
  }
}

@media screen and (max-width: 550px) {
  .main-grad {
    background:
      radial-gradient(
          circle at bottom left,
          var(--color-primary-tr20, #0d848433) 0%,
          var(--color-background-tr00, #ffffff00) 30%,
          var(--color-background-tr00, #ffffff00) 100%
        )
        bottom left,
      radial-gradient(
          circle at top right,
          var(--color-primary-tr20, #0d848433) 0%,
          var(--color-background-tr00, #ffffff00) 60%,
          var(--color-background-tr00, #ffffff00) 100%
        )
        center right;
  }

  .main-grad-tall {
    background: linear-gradient(
      75deg,
      var(--color-primary-tr30, #0d84844d) 0%,
      var(--color-background, white) 5%,
      var(--color-background, white) 95%,
      var(--color-primary-tr30, #0d84844d) 100%
    );
  }
}

@media print {
  * {
    color: black;
    background-color: white;
  }

  a {
    color: black;
  }

  .no-print {
    display: none !important;
  }

  .print-only {
    display: block !important;
  }
}
