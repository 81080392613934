@import "styles/variables.scss";
//Buttons

.hw-btn {
  font-size: 1rem;
  font-style: normal;
  line-height: 1;
  cursor: pointer;
  padding: 0.85rem 1rem;
  border: 1px solid var(--link-btn-base-color);
  background-color: var(--link-btn-base-color);
  color: var(--link-btn-fill-color);
  min-height: 24px;

  &:focus {
    outline: 2px dotted var(--color-neutral-dark);
    outline-offset: 2px;
  }

  &:disabled {
    opacity: 0.75;
    border: 1px solid var(--color-neutral-dark);
    background-color: var(--color-neutral);
    cursor: not-allowed;
  }

  &.unstyled {
    border-radius: 0;
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    text-transform: none;
    overflow: visible;
    font-family: inherit;
    color: inherit;
  }

  &.rounded {
    border-radius: 8px;
  }

  &.accent {
    border: 1px solid var(--color-accent-dark);
    background-color: var(--color-accent);
  }

  &.neutral {
    border: 1px solid var(--color-neutral-dark);
    background-color: var(--color-neutral);
  }

  &.neutral-light {
    border: 1px solid var(--color-neutral-x-light);
    background-color: var(--color-neutral-x-light);
    color: var(--color-neutral-dark);
  }

  &.warn {
    border: 1px solid var(--color-warning);
    background-color: var(--color-warning);
    color: var(--color-neutral-dark);
  }

  &.outline {
    background: none;
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    font-weight: 500;
  }

  &.underline {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid var(--color-primary);
    color: var(--color-primary);
    font-weight: 500;
  }

  &.warn.underline {
    color: var(--color-warning-dark);
    border-color: var(--color-warning);
  }

  &.accent.underline {
    border-bottom: 1px solid var(--color-accent-dark);
    color: var(--color-accent-dark);
  }

  &.neutral.underline {
    border-bottom: 1px solid var(--color-neutral-dark);
    color: var(--color-neutral-dark);
  }

  &.neutral-light.underline {
    border-bottom: 1px solid var(--color-neutral-light);
    color: var(--color-neutral-dark);
  }

  &.small {
    padding: 0.35rem 0.5rem;
    font-size: 0.83rem;
    line-height: 1;
  }

  &.small.unstyled {
    padding: 0;
  }

  &.unstyled.accent,
  &.unstyled.neutral {
    color: white;
  }

  &.outline.accent {
    color: var(--color-accent-dark);
    border-color: var(--color-accent-dark);
  }

  &.outline.neutral {
    color: var(--color-neutral-dark);
    border-color: var(--color-neutral-dark);
  }

  &.outline.neutral-light {
    color: var(--color-neutral-dark);
    border-color: var(--color-neutral-light);
  }

  &.outline.warn {
    color: var(--color-warning-dark);
    border-color: var(--color-warning);
  }
}

.hw-btn-icon {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;

  &.icon-only {
    margin-right: 0;
  }

  svg {
    width: 20px;
    height: 20px;
    fill: var(--link-btn-fill-color);
  }
}

.hw-btn.small .hw-btn-icon {
  width: 16px;
  height: 16px;

  svg {
    width: 16px;
    height: 16px;
  }
}

.hw-btn.neutral-light .hw-btn-icon svg {
  fill: var(--color-neutral-dark);
}

.hw-btn.underline .hw-btn-icon svg,
.hw-btn.outline .hw-btn-icon svg {
  fill: var(--color-primary);
}

.hw-btn.underline.accent .hw-btn-icon svg,
.hw-btn.outline.accent .hw-btn-icon svg {
  fill: var(--color-accent-dark);
}

.hw-btn.unstyled .hw-btn-icon svg,
.hw-btn.warn .hw-btn-icon svg,
.hw-btn.neutral.underline .hw-btn-icon svg,
.hw-btn.outline.neutral .hw-btn-icon svg,
.hw-btn.neutral-light.underline .hw-btn-icon svg,
.hw-btn.outline.neutral-light .hw-btn-icon svg {
  fill: var(--color-neutral-dark);
}

.hw-btn.underline.warn .hw-btn-icon svg,
.hw-btn.underline.warn .hw-btn-icon svg,
.hw-btn.outline.warn .hw-btn-icon svg {
  fill: var(--color-warning-dark);
}

.hw-btn.unstyled:disabled,
.hw-btn.button:disabled {
  opacity: 0.75;
  background-color: var(--color-neutral);
  border-color: var(--color-neutral);
  cursor: not-allowed;
}

.hw-btn.outline:disabled {
  background-color: transparent;
  cursor: not-allowed;
}

a.hw-btn {
  text-decoration: none;
}

//Input Fields
.hw-input {
  width: 100%;
  box-sizing: border-box;
  height: 38px;
  min-width: 24px;
  margin-top: 0.25em;
  padding: 8px;
  color: var(--color-neutral-x-dark);
  line-height: 30px;
  vertical-align: middle;
  font-size: 1em;
  border: 1px solid var(--color-neutral-x-dark);
  transition: border ease 0.2s;

  &:focus,
  &:active {
    box-shadow: $box-shadow-elevation-1dp;
  }

  &:focus,
  &:active,
  &:hover {
    background-color: var(--color-neutral-x-light);
  }

  &.underlined {
    padding: 8px 0;
    border: none;
    border-bottom: 1px solid var(--color-foreground);
  }

  &.rounded {
    border-radius: 4px;
  }

  &.rounder {
    border-radius: 8px;
  }

  .rounded.underlined,
  .rounder.underlined {
    border-radius: 0;
  }

  &.error {
    border-color: var(--color-important);
    border-width: 2px;
  }

  &.underlined:focus {
    border: none;
    border-bottom: 2px dotted var(--color-foreground);
    outline: none;
  }

  &.readOnly {
    border: none;
    cursor: not-allowed;
  }

  &.readOnly:focus {
    border: none;
    outline: none;
  }
}

.hw-input-lbl,
.hw-error-lbl {
  width: 100%;
  display: block;
  margin: 0.65em 0 0.25em;
  font-size: 0.75em;
  letter-spacing: 0.5px;
  line-height: 1em;
}

.hw-error-lbl {
  margin-top: 0.25em;
  margin-bottom: 0;
  color: var(--color-important);
}

// Checkboxes & Radios
.hw-checkbox,
.hw-radio {
  margin: 0;
  padding: 3px;
  height: 20px;
  width: 20px;
  display: inline-block;
  vertical-align: middle;
  accent-color: var(--color-primary);
}

.hw-checkbox-lbl,
.hw-radio-lbl {
  display: inline-block;
  vertical-align: middle;
  font-size: $font-size-normal;
  line-height: $line-height-normal;
  margin-left: 0.5rem;

  .right {
    margin-left: 0;
    margin-right: 0.5rem;
  }
}

.hw-checkbox:checked + .hw-checkbox-lbl,
.hw-radio:checked + .hw-checkbox-lbl {
  font-weight: 500;
}

// Select - Requires a wrapper element
.hw-select-wrapper {
  width: 100%;
  height: 38px;
  margin-top: 0.25em;
  color: var(--color-foreground);
  line-height: 30px;
  vertical-align: middle;
  border: 1px solid var(--color-foreground);
  transition: border ease 0.2s;

  &:has(select:focus) {
    outline: $focus-indicator;
    outline-offset: $focus-indicator-offset;
  }

  &:has(select:disabled) {
    opacity: 0.75;
    // text-decoration: line-through;
    border: 1px solid var(--color-neutral-dark);
    background-color: var(--color-neutral);
  }

  select {
    font-size: 1em;
    padding: 8px;
    cursor: pointer;
    width: 100%;
    height: 38px;
    border: none;
    background-color: transparent;
    border-right: 8px solid transparent;

    &:focus {
      outline: none;
    }
  }

  &.rounded {
    border-radius: 8px;
  }
}

// icon classes
.hw-icon-fill-light {
  fill: var(--color-primary-light);
}
.hw-icon-fill-dark {
  fill: var(--color-primary-dark);
}
.hw-icon-stroke-light {
  stroke: var(--color-primary-light);
}
.hw-icon-stroke-dark {
  stroke: var(--color-primary-dark);
}

// cards
.hw-card {
  display: block;
  position: relative;
  width: 100%;
  background-color: var(--color-neutral-xx-light);
  border: solid 2px var(--color-neutral-light);
  border-radius: 0.5rem;
  padding: 1rem;

  &:hover,
  &:focus,
  &:focus-within {
    box-shadow: $box-shadow-elevation-1dp;
    border-color: var(--color-neutral-minus);
  }

  &:focus,
  &:focus-within {
    outline: none;
    border-style: dotted;
    border-width: 2px;
  }
}

.hw-card-bg {
  position: absolute;
  inset: 0 0 0 0;
  background-color: var(--color-primary);
  opacity: 0;
}

.hw-card {
  &:hover .hw-card-bg,
  &:focus .hw-card-bg,
  &:focus-within .hw-card-bg {
    opacity: 0.05;
  }
}
