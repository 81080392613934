/* Only Sass variables in this file */

$color-forced-light: white;
$color-forced-dark: black;

$focus-indicator: 2px dotted var(--color-foreground);
$focus-indicator-contrast: 2px dotted var(--color-background);
$focus-indicator-medium: 2px dotted var(--color-outline-medium);
$focus-indicator-offset: 2px;

$font-size-small: 0.83rem;
$font-size-small-plus: 0.92rem;
$font-size-normal: 1rem;
$font-size-normal-plus: 1.25rem;
$font-size-large: 1.75rem;
$font-size-x-large: 2rem;

$line-height-small: 1.3;
$line-height-normal: 1.4;
$line-height-large: 1.5;

$box-shadow-elevation-1dp: 0px 3px 6px 0px var(--color-foreground-tr10);

$animation-time: 0.3s ease-out;

$end-of-page-icon-opacity: 0.6;

$footer-base-color: var(--footer-base-color);
$footer-fill-color: var(--footer-fill-color);

$font-family-primary: var(--font-family-primary);
$font-family-serif: var(--font-family-serif);
$font-family-mono: var(--font-family-mono);

$max-content-width: 800px;
$max-aside-width: 376px;
$content-gutter-width: 24px;
$max-page-width: calc($max-aside-width + $max-content-width + $content-gutter-width);
$max-search-width: 1000px;

$header-scroll-margin-top: 100px;

@mixin rtlDirectionAttributes($text-align: right, $direction: rtl) {
  &[lang='ar'],
  &[lang='fa'] {
    direction: $direction;
    text-align: $text-align;
  }
}
