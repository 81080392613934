@import "styles/variables.scss";
$large-icon-width: 87px;
$x-large-icon-width: 120px;

.HwNavigationSection {
  box-sizing: border-box;
}

.HwNavigationSection * {
  box-sizing: border-box;
}

/* Removes Where can I learn more? and Health Tools sections */
.HwSectionLearnMore,
.HwSectionHealthTools {
  display: none;
}

/* Page lifestyle image */
section:first-of-type
  .HwNavigationSection:has(figure.HwMedicalImagePi):not(:has(h2, p, ul, ol, img.HwAnatomySketch)) {
  display: block;
  padding: 0 0 1rem 1rem;
  float: right;
  clear: left;
}

.hw-medical-figure {
  margin: 0;
}

img.HwMedicalImage {
  width: 100%;
}

@-moz-document url-prefix() {
  img.HwMedicalImage[data-hw-width] {
    max-width: 600px;
  }
}

img.HwAnatomySketch {
  width: auto;
  height: auto;
}

.HwCspTopicImage {
  text-align: center;
  img {
    height: auto;
    width: 100%;
    max-width: 454px;
    box-shadow: 2px 2px 31px 0 var(--color-neutral-xx-dark-tr25);
    border: solid 4px var(--color-neutral-light);
    border-radius: 4px;
  }
}

.HwEmergencyNumber {
  color: var(--color-important);
  font-weight: 500;
}

.HwSectionTitle {
  margin-top: 2rem;
}

.HwMultumImportant {
  color: var(--color-important);
  font-size: 1em;
}

.HwSlideShow {
  margin: 2rem 0;
}

.HwSlide.HwDisplayNone {
  display: none;
}

.HwSlideTitle {
  font-size: $font-size-normal;
  line-height: normal;
  font-weight: 400;
  letter-spacing: normal;
  margin-top: 2rem;
  display: none;
}

.SlideShowImage {
  text-align: center;

  img {
    display: inline-block;
    max-width: 100%;
  }
}

.HwSlideButtonInformation,
.HwSlideButtonFrame {
  text-align: center;
  margin: 1rem 0;
}

.HwSlideButtonPrev,
.HwSlideButtonNext {
  font-size: 1rem;
  font-style: normal;
  line-height: 1;
  cursor: pointer;
  padding: 0.85rem 1rem;
  border: 1px solid var(--link-btn-base-color);
  border-radius: 0.5rem;
  background-color: var(--link-btn-base-color);
  color: var(--link-btn-fill-color);
  min-height: 24px;

  &:focus {
    outline: 2px dotted var(--color-neutral-dark);
    outline-offset: 2px;
  }

  &:disabled {
    visibility: hidden;
  }
}

.HwSlideButtonNext {
  margin-left: 0.75rem;
}

.HwAccessibilityText {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  background-color: white;
  color: black;
}

.HwSubSectionTitle {
  .HwIconArea {
    display: inline-block;
    vertical-align: middle;
    width: 56px;
  }
  .HwIconTextArea {
    display: inline-block;
    vertical-align: middle;
  }
}

.HwIconList {
  width: 100%;
  padding: 0;

  .HwIconListItem {
    display: table;
    width: 100%;
    border-collapse: collapse;

    .HwIconArea {
      display: table-cell;
      width: $large-icon-width;
      vertical-align: top;
    }

    .HwIconTextArea {
      display: table-cell;
      vertical-align: top;
      padding-left: 1rem;
      text-indent: -1.3rem;

      &:before {
        content: '\2022';
        font-size: 2em;
        padding-right: 0.5rem;
        vertical-align: middle;
      }
    }
  }
}

.HwPiRow {
  display: flex;
  align-items: flex-start;

  .HwIconArea {
    padding-right: 1rem;
  }
  .HwIconTextArea p:first-of-type {
    margin-top: 0;
  }
}

.HwSectionVendor blockquote {
  font-size: $font-size-small;
  line-height: $line-height-small;
  margin: 0;
  text-align: justify;
}

// ******* New PIs
.hwPanelColumn {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin: 2rem 0;
  width: 100%;

  .hwPanelOdd,
  .hwPanelEven {
    margin: 0;
    width: 47.5%;
    text-align: center;
    page-break-inside: avoid;

    img {
      max-width: 100%;
    }

    figcaption {
      text-align: start;
    }
  }

  .HwMedicalImagePi {
    width: auto;
  }
}

.hwPanelColumn2 {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 1rem;

  figure {
    width: calc(50% - 0.5rem);
  }
}

.hw-vl-list {
  margin: 2rem 0;
  padding: 0;
  list-style: none;
}

.hw-vl-list:has(figure) {
  & > li {
    margin: 0 0 1rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    page-break-inside: avoid;
    flex-wrap: wrap;

    figure {
      margin: 0;
      flex: 100px;

      img {
        width: 81px;
      }
    }

    p,
    p + ul {
      width: 100%;
      display: block;
    }
  }

  .hw-vl-caption {
    margin: 0;
    flex: calc(100% - 110px);
    p b {
      display: block;
    }
  }
}

// New, weird icon/title/list thing
// This doesn't seem brittle at all <-- (sarcasm)
// Hack hack hack hack
// Hack hack hack hack
// Lovely hack! Wonderful hack!
img.HwMedicalImage {
  &[data-hw-width='300'] {
    width: 300px;
  }
  &[data-hw-width='120'] {
    width: $x-large-icon-width;
  }

  &[data-hw-width='54'] {
    width: $large-icon-width;
  }

  &[data-hw-width='90'] {
    width: $large-icon-width;
  }
}

.hw-mm-image-doc .HwMedicalImageWrapper {
  margin: 0 auto;
  max-width: 600px;
}

.hw-medical-figure + p + ul,
.hw-medical-figure + p + ol {
  margin-bottom: 2.5rem;
}
// END New, weird icon/title/list thing

.HwSectionSpecialSection {
  margin: 2rem 0;
}

.HwSectionSpecialSection:has(h2.HwSectionTitle):has(figure) {
  padding: 1rem;
  border: solid 2px var(--color-primary-light);
  page-break-inside: avoid;

  .HwSectionTitle {
    margin-top: 0;
    color: var(--color-primary);
  }
}

@media screen and (max-width: 740px) {
  .hwPanelColumn {
    display: block;
    justify-content: unset;

    .hwPanelOdd,
    .hwPanelEven {
      width: 100%;
    }

    .HwMedicalImagePi {
      margin: 0;
      width: 100%;
    }
  }

  section:first-of-type
    .HwNavigationSection:has(figure.HwMedicalImagePi):not(
      :has(h2, p, ul, ol, img.HwAnatomySketch)
    ) {
    display: block;
    padding: 0;
    float: none;
    clear: none;
    max-width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .hw-vl-list {
    li {
      figure {
        flex: 65px;

        img {
          width: 54px;
        }
      }
    }

    .hw-vl-caption {
      flex: calc(100% - 80px);
      b {
        display: block;
      }
    }
  }
}

// ******* END: New PIs

// ******* New KB Docs

.HwSectionCYS {
  display: none;
}

.HwNavigationSection {
  dt {
    font-weight: 700;
  }
  dd {
    margin: 0;
    padding: 0 0 0.5em;
    padding-left: 1em;

    p {
      margin-top: 0.25rem;
    }
  }
}

.HwNavigationSection ol li > img {
  display: block;
}

.hw-table-border {
  width: 100%;
  border-collapse: collapse;
  border: solid 1px var(--color-neutral-dark);
  margin-bottom: 2rem;
}

.hw-table-border td,
.hw-table-border th {
  border: solid 1px var(--color-neutral-dark);
  padding: 5px 10px;
  vertical-align: top;
}

.hw-table-border tr:nth-child(even) {
  background-color: var(--color-neutral-x-light);
}

// Remove Related Information section with empty list
.HwSectionRelatedInformation:has(ul:empty) {
  display: none;
}

// Video Block
.hwMediaLinks {
  ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    gap: 2%;
    flex-wrap: wrap;
    align-items: stretch;

    li {
      display: block;
      width: 31%;
      margin-bottom: 1rem;
      overflow: hidden;
      border: 2px solid var(--color-neutral-light);
      border-radius: 8px;
      overflow: hidden;
      background-color: var(--color-neutral-xx-light);
      transition: all $animation-time;
      &:focus-within,
      &:hover,
      &:focus {
        box-shadow: $box-shadow-elevation-1dp;
        border-color: var(--color-neutral-minus);
        color: var(--color-primary-dark);
      }

      &:focus-within,
      &:focus {
        border-color: var(--color-neutral-dark);
        border-style: dotted;
      }

      .HwLinkMultiMedia {
        display: block;
        position: relative;
        img {
          width: 100%;
          height: auto;
        }
        .HwLinkText {
          display: block;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 0.5rem;
          font-weight: 500;
          height: 64px;
        }

        &:focus {
          outline: none;
        }

        &[data-link-type='video']::before {
          position: absolute;
          inset: 50px 0 0 50%;
          transform: translateX(-50%);
          width: 50px;
          height: 40px;
          padding: 3px 0 0;
          background-color: var(--color-neutral, #676767);
          border: 1px solid var(--color-neutral-xx-light, white);
          border-radius: 8px;
          text-align: center;
          opacity: 0.9;
          content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' height='32' width='32' %3E%3Cpath fill='white' d='M8 5v14l11-7z'%3E%3C/path%3E%3C/svg%3E");
        }

        &[data-link-type='video']:hover::before {
          opacity: 1;
        }
      }
    }
  }
}

.HwCreditsSection .HwContentInformation {
  font-size: $font-size-small;
  line-height: $line-height-small;

  .HwLastUpdated {
    display: none;
  }
}

@media screen and (max-width: 700px) {
  .hwMediaLinks ul li {
    width: 49%;
    .HwLinkMultiMedia[data-link-type='video']::before {
      top: 40%;
      transform: translate(-50%, -50%);
    }
  }
}

@media screen and (max-width: 640px) {
  .hwPanelColumn2 {
    display: block;

    figure {
      width: 100%;

      img {
        width: 100%;
        max-width: 600px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .hwMediaLinks ul li .HwLinkMultiMedia[data-link-type='video']::before {
    top: 35%;
    transform: translate(-50%, -50%);
  }
}

@media screen and (max-width: 400px) {
  .hwMediaLinks ul {
    display: block;
    gap: 0;
    li {
      width: 100%;
      .HwLinkMultiMedia[data-link-type='video']::before {
        top: 40%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

// ******* END: New KB Docs
