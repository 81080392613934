.messages {
  z-index: 15000;
  position: fixed;
  top: 0;
  width: 100%;
  opacity: 0.9;
}
.messages path {
  fill: #fff;
}
.closeMessages {
  display: block;
  position: absolute;
  top: 13px;
  right: 23px;
  width: 25px;
  height: 25px;
  color: #fff;
}
.closeMessages svg {
  width: 16px;
  height: 16px;
}
.messageList {
  margin: 0;
  padding: 15px;
  overflow: auto;
  max-height: 100px;
}
.messageList ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.messageList a:hover {
  opacity: 0.7;
}

.errors {
  background: #ca0000;
  color: #fff;
}
.errors a {
  color: #fff;
}

.warnings {
  background: var(--color-warning, #ffb71b);
}
.warnings a {
  color: #fff;
}
