@import "styles/variables.scss";
.icon,
.iconOutline {
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  fill: var(--color-neutral-dark);
  width: 100%;
  height: auto;
}

.iconImage {
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  height: auto;
}

.iconLight {
  fill: var(--color-neutral-xx-light);
}

.logoLight path {
  fill: var(--color-neutral-xx-light);
}

.iconPrimary {
  fill: var(--color-primary);
}

.iconStrokePrimary {
  stroke: var(--color-primary);
}

.iconStrokePrimaryDark {
  stroke: var(--color-primary-dark);
}

.iconPrimaryLight {
  fill: var(--color-primary-light);
}

.iconPrimaryExtraLight {
  fill: var(--color-primary-x-light);
}

.iconPrimaryDark {
  fill: var(--color-primary-dark);
}

.iconPrimaryDarker {
  fill: var(--color-primary-darker);
}

.iconNeutralMedium {
  fill: var(--color-neutral-minus);
}

.iconNeutralDark {
  fill: var(--color-neutral-dark);
}

.iconNeutralExtraDark {
  fill: var(--color-neutral-x-dark);
}

.iconHeaderBackground {
  fill: var(--header-base-color);
}

.iconHeaderForeground {
  fill: var(--header-fill-color);
}

.iconCardBackground {
  fill: var(--card-base-color);
}

.iconCardForeground {
  fill: var(--card-fill-color);
}

.iconBackground {
  fill: var(--color-background);
}

.iconForeground {
  fill: var(--color-foreground);
}

.iconStrokeForeground {
  stroke: var(--color-foreground);
}

.iconOutline {
  stroke: var(--color-neutral-dark);
  fill: none;
}

.iconOutlineLight {
  stroke: var(--color-neutral-xx-light);
  fill: none;
}

.iconLightYellow {
  fill: var(--color-warning);
}

.iconLightRed {
  fill: var(--color-important);
}

.circle {
  display: inline-block;
  box-sizing: border-box;
  vertical-align: middle;
  width: 32px;
  height: 32px;
  border: 3px solid var(--color-neutral-dark);
  border-radius: 50%;
  text-align: center;
}

.circle_light {
  border-color: var(--color-neutral-xx-light);
}

.circle .icon {
  width: 70%;
}

.circle_check .icon {
  vertical-align: top;
  margin-top: -2px;
  margin-right: -2px;
}

.outline {
  stroke: var(--color-neutral-dark);
}

.thumb_fill {
  fill: var(--color-neutral-xx-light);
}

.icon_inverse {
  fill: var(--color-neutral-xx-light);
}

.icon_inverse .thumb_fill {
  fill: var(--color-primary);
}

@media (forced-colors: active) {
  .outline {
    stroke: $color-forced-light;
  }
  .icon,
  .iconOutline {
    fill: $color-forced-light;
  }
}

@media (forced-colors: active) and (prefers-color-scheme: light) {
  .outline {
    stroke: $color-forced-dark;
  }
  .icon,
  .iconOutline {
    fill: $color-forced-dark;
  }
}
